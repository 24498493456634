@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'); */

*,*:after,*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.2s;
  /* font-family: 'Poppins', sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}